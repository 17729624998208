import * as React from "react";
import {ReactComponent as Sun} from "../images/sun.svg";
import {ReactComponent as Moon} from "../images/moon.svg";
import DataStorage from "./data-storage";

export default function ThemeToggle() {
    return (
        <div className="theme-toggle">
            <div className="theme to-light" onClick={DataStorage.set.bind(DataStorage, 'theme', 'light')}>
                <Sun fill="var(--color-primary)" />
            </div>
            <div className="theme to-dark" onClick={DataStorage.set.bind(DataStorage, 'theme', 'dark')}>
                <Moon fill="var(--color-primary)" />
            </div>
        </div>
    );
}
