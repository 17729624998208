import * as React from "react";

export default {
    storageKey: 'site-settings',
    defaultSettings: {},
    onChangeCallbacks: {},
    storageData: null,

    init() {
        if (!this.storageData) {
            let storageData = null;
            if (typeof localStorage !== 'undefined') {
                storageData = localStorage.getItem(this.storageKey);
                try {
                    storageData = JSON.parse(storageData);
                } catch (e) {
                    storageData = null;
                }
            }

            if (!storageData) {
                storageData = {};
            }
            this.storageData = storageData;
            this.save();
        }
    },

    save() {
        if (typeof localStorage !== 'undefined') {
            localStorage.setItem(this.storageKey, JSON.stringify(this.storageData));
        }
    },

    get(key) {
        this.init();
        return this.storageData[key] || this.defaultSettings[key];
    },

    set(key, value) {
        this.init();
        this.storageData[key] = value;
        this.save();
        if (this.onChangeCallbacks[key]) {
            for (const callback of this.onChangeCallbacks[key]) {
                callback(value);
            }
        }
    },

    registerDefault(key, value) {
        this.init();
        this.defaultSettings[key] = value;
        if (typeof this.storageData[key] === 'undefined') {
            this.set(key, value);
        }
    },

    onChange(key, callback) {
        if (!this.onChangeCallbacks[key]) {
            this.onChangeCallbacks[key] = [];
        }
        this.onChangeCallbacks[key].push(callback);
    }
}
