import * as React from "react";
import seedrandom from "seedrandom";

export default function DotPattern() {
    const dotMaxSize = 0.7, dotMinSize = 0.3, width = 10, height = 10;
    let random = seedrandom(Math.floor(Date.now() / (1000 * 3600 * 24)));
    const randomRange = function (from, to) {
        return random() * (to - from) + from;
    };
    return (
        <div className="dot-pattern">
            {(new Array(width * height)).fill(null).map((_, i) => {
                let locW = (i % width) / width * 100, locH = (i / height) / height * 100,
                    stepW = 1.0 / width * 100, stepH = 1.0 / height * 100;
                let size = randomRange(dotMinSize, dotMaxSize);
                let top = randomRange(locH, locH + stepH) + '%';
                let left = randomRange(locW, locW + stepW) + '%';
                return <span key={i} style={{width: size + 'rem', height: size + 'rem', top: top, left: left}}/>;
            })}
        </div>
    )
}
