import * as React from "react"
import '../styles/base.scss';
import Nav from "../components/nav";
import Footer from "../components/footer";
import Theme, {colorVars, themeData} from "./theme";
import DataStorage from "./data-storage";
import {Helmet} from "react-helmet";

export default function Frame(props) {
    function objectToCss(object) {
        let result = '{';
        for (const key in object) {
            let cssKey = key;
            if (key === 'backgroundColor') {
                cssKey = 'background-color';
            }
            result += cssKey + ':' + object[key] + ';';
        }
        result += '}';

        return result;
    }

    let themeStyles = '';
    let themes = themeData();
    for (const theme in themes) {
        themeStyles += '.theme-' + theme + objectToCss(colorVars(...themes[theme].colors));
    }

    DataStorage.onChange('theme', function () {
        if (typeof window !== 'undefined') {
            window.SiteSettings.setTheme(Theme());
        }
    });

    return (
        <main>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{props.title || "Hey! I'm Hieu"}</title>
                <meta name="description" content="Hieu is a full stack e-commerce developer. Magento? Wordpress? He sure can handle them."/>
                <link rel="icon" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text x=%22-3%22 y=%2278%22 font-size=%2280%22>👋</text></svg>"/>
                <script>
                    {`
                    window.SiteSettings = {
                        getDefaultTheme: function () {
                            let settings = localStorage.getItem('site-settings');
                            if (settings) {
                                try {
                                    settings = JSON.parse(settings);
                                    if (settings.theme === 'light' || settings.theme === 'dark') {
                                        return settings.theme;
                                    }
                                } catch (e) {
                                }
                            }
                            
                            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                                return 'dark';
                            }
                            
                            return 'light';
                        },
                        setTheme: function (theme = null) {
                            if (!theme) {
                                theme = this.getDefaultTheme();
                            }
                            
                            let html = document.getElementsByTagName('html')[0];
                            
                            for (let className of html.classList) {
                                if (className.startsWith('theme-')) {
                                    html.classList.remove(className);
                                }
                            }
                            
                            html.classList.add(['theme-' + theme]);
                        }
                    };
                    window.SiteSettings.setTheme();
                    `}
                </script>
                <style>
                    {themeStyles}
                </style>
            </Helmet>
            <Nav/>
            {props.children || ''}
            <Footer/>
        </main>
    )
}
