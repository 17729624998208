import * as React from "react";
import Color from 'color';
import DataStorage from "./data-storage";

export function colorVars(colorPrimary, colorSecondary, colorForeground, colorBackground) {
    let primary = Color(colorPrimary),
        secondary = Color(colorSecondary),
        foreground = Color(colorForeground),
        background = Color(colorBackground),
        white = Color('#fff'),
        black = Color('#000'),
        shadedColor;
    if (primary.isDark()) {
        shadedColor = {
            '--color-primary-light': primary.mix(white, 0.8).toString(),
            '--color-secondary-light': secondary.mix(white, 0.8).toString(),
        }
    } else {
        shadedColor = {
            '--color-primary-light': primary.mix(black, 0.8).toString(),
            '--color-secondary-light': secondary.mix(black, 0.8).toString(),
        }
    }

    return {
        '--color-background': colorBackground,
        '--color-primary': colorPrimary,
        '--color-secondary': colorSecondary,
        ...shadedColor,
        '--color-black': colorForeground,
        '--color-white': colorBackground,
        '--color-gray': foreground.mix(background).toString(),
        color: colorForeground,
        backgroundColor: colorBackground,
    }
}

export function themeData() {
    return {
        light: {
            name: 'Light',
            colors: ['#6667ab', '#a63d40', '#0d1321', '#fff']
        },
        dark: {
            name: 'Dark',
            colors: ['#8373e1', '#e73e39', '#ddd', '#222']
        }
    }
}

export default function Theme() {
    let defaultTheme = 'light';
    if (typeof window !== 'undefined' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        defaultTheme = 'dark';
    }
    DataStorage.registerDefault('theme', defaultTheme);
    let savedTheme = DataStorage.get('theme');
    if (!savedTheme || !themeData()[savedTheme]) {
        savedTheme = 'light';
        DataStorage.set('theme', savedTheme);
    }

    return savedTheme;
}
