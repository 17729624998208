import * as React from "react";
import Tippy, {useSingleton} from "@tippyjs/react";

export default function SocialLinks() {
    const [source, target] = useSingleton();
    return (
        <ul className="social-links">
            <Tippy singleton={source} delay={[null, 500]} placement="top" moveTransition="transform .2s ease-out"
                   appendTo={'parent'}
                   animation="shift-toward"/>
            <li className="social-link">
                <Tippy content="LinkedIn" singleton={target}>
                    <a target="_blank" rel="nofollow noopener" href="https://www.linkedin.com/in/hieudmg/">
                        <i className="icon icon-linkedin-square color-linkedin"/>
                    </a>
                </Tippy>
            </li>
            <li className="social-link">
                <Tippy content="Github" singleton={target}>
                    <a target="_blank" rel="nofollow noopener" href="https://github.com/hieudmg/">
                        <i className="icon icon-github-square color-github"/>
                    </a>
                </Tippy>
            </li>
            <li className="social-link">
                <Tippy content="Skype" singleton={target}>
                    <a target="_blank" rel="nofollow noopener" href="https://join.skype.com/invite/Kmzn7f3bXi15/">
                        <i className="icon icon-skype color-skype"/>
                    </a>
                </Tippy>
            </li>
        </ul>
    )
}
