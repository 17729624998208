import * as React from "react";
import SocialLinks from "./social-links";
import DotPattern from "./dot-pattern";

export default function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="row-bottom pt5 pb2 row-wrap">
                    <SocialLinks/>
                </div>
                <DotPattern/>
            </div>
        </footer>
    )
}
