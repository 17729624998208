import * as React from "react";
import '../styles/nav.scss';
import {Link} from "gatsby";
import DotPattern from "./dot-pattern";
import ThemeToggle from "./theme-toggle";

export default function Nav() {
    return (
        <nav>
            <div className="container">
                <ul className="nav">
                    <li className="nav-item">
                        <Link to="/" activeClassName="active">Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/about/" activeClassName="active" partiallyActive={true}>About</Link>
                    </li>
                    <li className="nav-item nav-item-left">
                        <ThemeToggle/>
                    </li>
                </ul>
                <DotPattern/>
            </div>
        </nav>
    )
}
